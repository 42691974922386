import React from 'react'
import { Helmet } from 'react-helmet'
import AboutUsContent from '../components/AboutUs/AboutUs'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { ABOUT_US as ABOUT_US_LINK } from '../components/internal-links'
import { generateBreadcrumbsSchema } from '../lib/generate-schema'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Homepage',
    url: '/',
  },
  {
    title: 'About Us',
    url: ABOUT_US_LINK,
  },
]

const AboutUs: React.FC = () => {
  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs}>
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="About MegaRummy App"
        description="Get to know about our vision, mission, core values and more. Click to read about how MegaRummy is on a journey to redefine online real money Rummy! "
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <AboutUsContent />
    </Layout>
  )
}

export default AboutUs
