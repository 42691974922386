import React from 'react'

import './Title.scss'

interface Props {
  color?: string
  className?: string
  children: React.ReactNode
}

const Title: React.FC<Props> = ({ children, color, className = '' }) => {
  const style = (color && { color }) || {}

  return (
    <h2 className={`title ${className}`} style={style}>
      {children}
    </h2>
  )
}

export default Title
