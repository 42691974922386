import React from 'react'
import './AboutUs.scss'
import Container from 'react-bootstrap/esm/Container'
import Title from '../Title/Title'

const AboutUs: React.FC = () => {
  return (
    <div id="AboutUs">
      <div className="header">
        <Container>
          <h1 className="page-title">About Us</h1>
          <p>
            Mega brings to you the joy of gaming with the thrill of real money,
            and delivers a truly unique entertainment experience. Play your
            favorite skill based Cards games — only on Mega, India&apos;s
            hottest real money gaming platform.
          </p>
          <p>
            We bring you an unmatched entertainment experience — classic as well
            as brand-new innovative games with the perfect real money thrills —
            in a safe, trusted gaming environment. We believe in responsible
            gaming and have built a platform where safety, trust and fairplay is
            of the utmost importance. To deliver on that promise, we allow only
            100% verified profiles on our platform, and feature only games of
            skill, with certified gameplay
          </p>
          <p>
            Play Rummy with real players and win exciting real cash rewards,
            only on Mega!
          </p>
          <p>
            Choose from a wide range of real cash contests and tables, with
            entry fees starting from ₹1. So, whatever be the size of your
            ambitions and your level of comfort, we have something that
            perfectly suits you.
          </p>
          <p>
            Play well designed games, with smooth and certified gameplay, and
            transfer your winnings with the fastest cash withdrawals ever in
            India.
          </p>
          <p>
            Make the most out of your gaming — minimise distractions, maximise
            fun and win big!
          </p>
        </Container>
      </div>

      <div className="content">
        <Container>
          <Title color="#000" className="vision">
            Unparalleled <span className="underline">entertainment</span> for
            everyone
          </Title>
          <div className="vision-blocks">
            <Title color="#000" className="h">
              Our Vision
            </Title>
            <p>
              Mega combines the joy of gaming with the thrill of real money —
              creating the most immersive form of entertainment that is
              irresistible and irreplaceable. Gaming allows people of all ages
              to enjoy together, thus making it an integral part of the human
              social experience. We envision to elevate that experience to the
              next level.
            </p>
          </div>
          <div className="vision-blocks">
            <Title color="#000" className="h">
              Our Mission
            </Title>
            <p className="styled-para">
              We deliver unparalleled entertainment by — focusing on empathy led
              emotional design, and data driven decision making, by cultivating
              a responsible gaming ecosystem with the highest ethical standards.
            </p>
          </div>
          <div className="vision-blocks">
            <Title color="#000" className="h">
              Our Core Values
            </Title>

            <h3 className="styled-h3">Data-driven decision making</h3>
            <p>
              Our strength is in the ability to make the right decisions
              consistently, for a continuous growth and a constantly refining
              gaming experience. By questioning ourselves at every stage of the
              process and continuous experimentation, testing and data driven
              decision making, we generate actionable insights for a refined
              gaming experience and unprecedented growth.
            </p>

            <h3>Designing for empathy</h3>
            <p>
              With empathy at the core, we design for a clean, inclusive and
              positive emotional experience. Simplicity and fun is central to
              our design principles, whether it is any of the games, the entire
              platform or our work culture.
            </p>

            <h3>Building for Trust</h3>
            <p>
              It takes a greater, continuous effort to maintain trust in a
              relationship than to start it. We are building for the long run
              and at every step of it, our decision making is driven with the
              purpose of maintaining player trust. Everyone who plays on our
              platform should be able to do so with consent, comfort and
              confidence, at all points of time. Each decision we take is to
              maintain an assurance of quality, fairplay, reliability and
              privacy, and thus build a relationship that&#39;s based on trust .
            </p>

            <p>
              <strong>
                It takes a great amount of effort to build trust and maintaining
                it is even harder. We are building for the long run, and at
                every step, trust is the center of every decision we make.
                Everyone and anyone who plays on Mega is entitled to consent,
                comfort, and 100% confidence, at any point in time. We are here
                to set the gold standard of reliability, privacy, and
                responsible gaming.
              </strong>
            </p>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default AboutUs
